#gallery{
  position: relative;
}

#gallery::before{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url(../assets/images/dream.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  /* background-color: var(--lavender); */
  background-attachment: fixed;
  filter: saturate(50%);
  /* background-blend-mode: soft-light; */
  opacity: 0.25;
  z-index: 0;
}

#gallery > * {
  z-index: 1;
}

.project-list {
  list-style-type: none;
  padding: 0;
}

/* ========================================= *\
|| >> MOBILE <<
\* ========================================= */

@media only screen and (max-width: 480px) {
  #gallery {
    padding-inline: 1rem;
  }
}